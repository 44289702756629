
const routes = [
  {
    path: '/',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      { path: '', name: 'user', component: () => import('pages/UserPage.vue') }
    ]
  },
  {
    path: '/master',
    component: () => import('layouts/MasterLayout.vue'),
    children: [
      { path: '', name: 'master', component: () => import('pages/MasterPage.vue') }
    ]
  },
  {
    path: '/screen',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'screen', component: () => import('pages/ScreenPage.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
